import React from 'react';
import { useTitle } from '../../hooks/useTitle';
import { DataScienceCard } from './components';
//import { DSMethodologySection } from './components/DSMethodologySection';

export const DataScience = ({ title }) => {
  useTitle(title);

  return (
    <section id="data-science" className="bg-inherent w-full max-x-2xl mx-auto py-16">
      <h1 className="text-center text-5xl py-7 font-bold text-orange-400">PROJECTS</h1>
      <DataScienceCard />

    </section>
  );
};

