import { Link } from 'react-router-dom';
import Forged from '../../../assets/projects/forgery-sample.jpeg';
import LaunchSite from '../../../assets/projects/launch-site.jpg';
import HousePrice from '../../../assets/projects/house-price-prediction.jpg';
import SpaceX from '../../../assets/projects//spacex9.jpg';
import SpaceX9 from '../../../assets/projects/spacex-dashboard.jpg';
import MovieHub from '../../../assets/projects/movie-hub.jpg';

const projects = [
  
  {
    id: 1,
    title: 'House Price Prediction',
    description: 'This repository contains a Streamlit Web Application that predicts the Boston house prices based on various features. The app provides an interface for users to adjust input parameters and obtain predictions along with explanations.',
    githubLink: 'https://tedoaba-house-price-prediction.streamlit.app/',
    imageSrc: HousePrice,
    techStack: ['Python', 'Streamlit', 'Scikit-learn', 'Shap', 'RandomForest Regression', 'Pandas'],
  },
  {
    id: 2,
    title: 'Image Tampering Recognition using YOLOv5',
    description: 'This repository contains an implementation of image tampering recognition using YOLOv5, based on the paper "Image Tampering Recognition Algorithm using Improved YOLOv5" by Z. Liu.',
    githubLink: 'https://github.com/tedoaba/YOLOv5-Image-Tampering-Detection',
    imageSrc: Forged,
    techStack: ['Python', 'YOLOv5', 'Pytorch', 'Roboflow', 'ClearML'],
  },
  {
    id: 3,
    title: 'Interactive Visual Analysis Using Folium',
    description: 'Selecting an appropriate launch site is crucial for successful rocket launches. Factors such as geographical location, climate, and safety are significant considerations in this process. This repository provides tools to visualize and analyze potential launch sites using Folium, a popular Python library for interactive maps',
    githubLink: 'https://github.com/tedoaba/Interactive-Visual-Analysis-with-Folium',
    imageSrc: LaunchSite,
    techStack: ['Python', 'Folium', 'Pandas'],
  },
  {
    id: 4,
    title: 'Space X Falcon 9 First Stage Landing Prediction',
    description: 'This repository contains code and data for predicting the landing location of the SpaceX Falcon 9 first stage booster after launch. Predicting the landing location is crucial for planning recovery operations and ensuring the safety and success of SpaceX missions. This prediction is based on various factors such as launch trajectory, weather conditions, and mission parameters.',
    githubLink: 'https://github.com/tedoaba/SpaceX-Landing-Outcome-Prediction-with-Machine-Learning',
    imageSrc: SpaceX,
    techStack: ['Python', 'Sklearn', 'Pandas', 'Logistic Regression', 'SVC', 'Decision Tree Classifier', 'KNeighbors Classifier', 'Matplotlib', 'Seaborn', 'NumPy'],
  },
  {
    id: 4,
    title: 'Launch Records Dashboard',
    description: 'This repository contains a dashboard application built using Dash and Plotly libraries in Python. The dashboard visualizes SpaceX launch records, allowing users to explore data related to launch sites, payload mass, and launch success.',
    githubLink: 'https://github.com/tedoaba/SpaceX-Dashboard',
    imageSrc: SpaceX9,
    techStack: ['Python', 'Dash', 'Pandas', 'Plotly'],
  },
  {
    id: 5,
    title: 'MovieHub',
    description: 'MovieHub is a web application that allows users to explore a vast collection of movies along with their descriptions, budgets, durations, release dates, and more.',
    githubLink: 'https://tedoaba-movie-hub.vercel.app/',
    imageSrc: MovieHub,
    techStack: ['ReactJS', 'TailwindCSS', 'TMDB API'],
  },

  // Add more projects as needed
];

export const DataScienceCard = () => {


  return (

    <div className="flex flex-wrap justify-center gap-8">
    {projects.map((project, index) => (
      <div key={project.id} className={`w-4/5 sm:w-1/2 lg:w-1/4 hover:rotate-2`} >
        
        <div className="bg-inherit p-5 rounded-3xl shadow-2xl shadow-orange-400">
        <Link 
        to={project.githubLink}
        target='_blank'
        rel='noopener noreferrer'
        >
          <img className="" src={project.imageSrc} alt="" />
        </Link>
          <Link 
          to={project.githubLink}
          target='_blank'
          rel='noopener noreferrer'
          >
            <h3 className="mb-2 text-2xl text-center font-bold tracking-tight text-orange-400">
              {project.title}
            </h3>
          </Link>
          <p className="mb-3 font-normal text-center text-white">{project.description}</p>
          <p className="mb-3 font-normal text-white">
            <span className='text-orange-400 font-bold'>Tech Stack: </span>{project.techStack.join(', ')}
          </p>
          <Link
            to={project.githubLink}
            target='_blank'
            rel='noopener noreferrer'
            className="inline-flex items-center lg:ms-20 px-5 py-3 text-lg font-medium text-center text-white bg-orange-400 rounded-lg hover:bg-orange-500 focus:ring-4 focus:outline-none focus:ring-orange-300 "
          >
            Explore
          </Link>
        </div>
      </div>
    ))}
  </div>

  );
};
