import Arifpay from '../../assets/images/arifpay-logo.jpg';
import AASTU from '../../assets/images/aastu-logo.png';
import classNames from 'classnames';
import 'animate.css/animate.min.css';
import { useEffect, useState, useRef } from "react";

export const Experience = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    const handleScroll = () => {
        const section = sectionRef.current;
        if (section) {
            const rect = section.getBoundingClientRect();
            setIsVisible(rect.top <= window.innerHeight && rect.bottom >= 0);
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            });
        }, { threshold: 0.5 });

        observer.observe(sectionRef.current);

        // Cleanup function for the observer
        return () => {
            observer.disconnect();
        };
    }, []);

    // Add scroll event listener
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <section ref={sectionRef} id='experience' className=" w-full max-x-2xl mx-auto lg:py-16 bg-inherent">
            <div className={classNames('flex md:flex-row flex-col justify-center gap-5 shadow-2xl lg:mx-20', {'animate__animated ': isVisible,})}>

            <div className={classNames('basis-1/2 flex flex-col justify-center p-4 lg:p-20', {'animate__animated animate__rotateInDownRight': isVisible,})}>
                    <img src={Arifpay} alt="Arifpay" className="rounded-xl -rotate-12 m-4" />
                    <img src={AASTU} alt="AASTU" className="rounded-xl rotate-12 m-4" />
                </div>
                
                <div className={classNames('basis-1/2 rounded-2xl lg:px-12 px-4 lg:py-12', {'animate__animated animate__rotateInDownLeft': isVisible,})}>
                    <h1 className="text-orange-400 font-extrabold text-2xl sm:text-5xl p-5 text-center">EXPERIENCE</h1>      
                    <ol className="relative border-s border-orange-400 m-5 justify-center">                  
                        <li className="mb-10 ms-4">
                            <div className="absolute w-3 h-3 bg-orange-400 rounded-full mt-1.5 -start-1.5 border border-white"></div>
                            <time className="mb-1 text-sm font-normal leading-none text-orange-400">February 2020 - Present</time>
                            <h3 className="text-lg font-bold text-tedo-light-yellow">Data Scientist | Information and Network Security Agency </h3>
                            <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">As a versatile professional, I'm currently engaged in computer vision projects, utilizing various deep learning architectures like CNN, YOLO, RCNN, MaskRCNN, UNET, and more. These endeavors aim to innovate security measures and streamline processes through cutting-edge technology and practical application. My role epitomizes a commitment to innovation and advancement in information and network security.</p>
                            
                        </li>
                        <li className="mb-10 ms-4">
                            <div className="absolute w-3 h-3 bg-orange-400 rounded-full mt-1.5 -start-1.5 border border-white "></div>
                            <time className="mb-1 text-sm font-normal leading-none text-orange-400">November 2022 - February 2023</time>
                            <h3 className="text-lg font-bold text-tedo-light-yellow">Web Developer | Arifpay Financial Technologies S.C </h3>
                            <p className="text-base font-normal text-gray-500 dark:text-gray-400">During my internship at Arifpay Financial Technologies S.C, I was immersed in website customization projects that demanded a diverse skill set. Leveraging JavaScript, ReactJS, NodeJS, Python, and Odoo, I contributed to the development and enhancement of web solutions. This experience deepened my understanding of web development technologies and sharpened my ability to collaborate effectively within a dynamic team environment.</p>
                        </li>
                        <li className="mb-10 ms-4">
                            <div className="absolute w-3 h-3 bg-orange-400 rounded-full mt-1.5 -start-1.5 border border-white "></div>
                            <time className="mb-1 text-sm font-normal leading-none text-orange-400">November 2021 - February 2022</time>
                            <h3 className="text-lg font-bold text-tedo-light-yellow">Networks Engineer | Addis Ababa Science and Technology University </h3>
                            <p className="text-base font-normal text-gray-500 dark:text-gray-400">During my tenure as a Network Engineer at AASTU, I was responsible for maintaining various IT infrastructures, ensuring seamless operations and reliability. Additionally, I undertook an internship project to design and implement a CCTV Surveillance System for the institution. This hands-on experience allowed me to apply my skills in networking and infrastructure management to create a robust security solution tailored to the specific needs of AASTU. Through this project, I gained valuable insights into the practical implementation of network technologies in real-world scenarios.</p>
                        </li>
                    </ol>
                </div>

                
            </div>
        </section>
    )
}

