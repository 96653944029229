
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Footer, Header } from '../components';
import { HomePage, MyContacts, MyEducation, MyExperience } from '../pages';
import { PageNotFound } from '../pages/PageNotFound';
import { DataScience } from '../pages/projects/DataScience';
import { WebDev } from '../pages/projects/WebDev';
import { ProjectManagement } from '../pages/projects/ProjectManagement';
import { AboutMe } from '../pages/about/AboutMe';
//import { JumbotronComponent } from '../pages/common/JumbotronComponent';
import { Contact } from '../pages/common';

//<Route path='/' element={<JumbotronComponent title="" />} />

export const AllRoutes = () => {
  return (
    <Router>
        
        <main className='bg-center bg-no-repeat bg-dev-code bg-gray-950 bg-blend-multiply'>
          <Header />
          <Routes>
              <Route path='/' element={<HomePage title="Home" />} />
              <Route path='/about-me' element={<AboutMe title="About Me" />} />
              <Route path='/education' element={<MyEducation title="Education" />} />
              <Route path='/experience' element={<MyExperience title="Experience" />} />
              <Route path='/projects/data-science' element={<DataScience title="Data Science" />} />
              <Route path='/projects/web-development' element={<WebDev title="Web Development" />} />
              <Route path='/projects/project-management' element={<ProjectManagement title="Project Management" />} />
              <Route path='/contact-me' element={<Contact title="Contacts" />} />

              <Route path='*' element={<PageNotFound title="PageNotFound" />} />
          </Routes>
          <Footer />
        </main>
    </Router>
  )
}
