import { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom"
import Logo from '../../assets/tedoaba-log.png';

export const Header = () => {
    const [hidden, setHidden] = useState(true);
    const [activeDropdown, setActiveDropdown] = useState(null);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setActiveDropdown(null);
        }
        };

        document.addEventListener('mousemove', handleClickOutside);

        return () => {
        document.removeEventListener('mousemove', handleClickOutside);
        };
    }, []);
    
  return (
    <header className="bg-blue-950">
        <nav className= " w-screen bg-center bg-no-repeat bg-tedo-light bg-blue-950 bg-blend-multiply">
            <div className= "max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4 relative">
                <Link to='/home' className= "flex items-center space-x-3 rtl:space-x-reverse">
                    <img src={Logo} alt="LOGO" className= "h-8" />
                    <span className= "self-center text-2xl font-semibold whitespace-nowrap hidden text-white hover:text-orange-400">TADESSE ABATENEH</span>
                </Link>

                {/* Menu Toggle Button */}
                <button
                onClick={() => setHidden(!hidden)}
                data-collapse-toggle="navbar-dropdown"
                type="button"
                className= "inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-white rounded-lg md:hidden hover:bg-orange-400 focus:outline-none focus:ring-2 focus:ring-gray-200"
                aria-controls="navbar-dropdown"
                aria-expanded="false"
            >
                <span className="sr-only">Open main menu</span>
                <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
                >
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
                </svg>
                </button>

                {/* Content for Menu Toggle Button */}
                <div className= {`${hidden ? 'hidden' : ''} w-full md:block md:w-auto `} id="navbar-dropdown" ref={dropdownRef}>
                    <ul className= "flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-inherent md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0">
                        <li ><Link to='/' aria-current='page'  className= "block py-2 px-3 text-white bg-inherent rounded md:bg-transparent md:hover:text-orange-400 md:p-0 " >Home</Link></li>
                        <li ><a href='/education'  className= "block py-2 px-3 text-white rounded hover:bg-gray-1000 md:hover:bg-transparent md:border-0 md:hover:text-orange-400 md:p-0 " >Education</a></li>
                        <li><a href="/projects/data-science"  className= "block py-2 px-3 text-white rounded hover:bg-gray-1000 md:hover:bg-transparent md:border-0 md:hover:text-orange-400 md:p-0 ">Projects</a></li>
                        <li ><a href='/experience'  className= "block py-2 px-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-orange-400 md:p-0" >Experience</a></li>
                        <li ><a href='/contact-me'  className=  "block py-2 px-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-orange-400 md:p-0" >Contact Me</a></li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
  )
}
