
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import 'animate.css/animate.min.css';
import { useEffect, useState, useRef } from 'react';

export const Education = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    const handleScroll = () => {
        const section = sectionRef.current;
        if (section) {
            const rect = section.getBoundingClientRect();
            setIsVisible(rect.top <= window.innerHeight && rect.bottom >= 0);
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            });
        }, { threshold: 0.5 });

        observer.observe(sectionRef.current);

        // Cleanup function for the observer
        return () => {
            observer.disconnect();
        };
    }, []);

    // Add scroll event listener
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return(
        <section ref={sectionRef} id='education' className=" w-full max-x-2xl mx-auto lg:py-16 bg-inherent">
            <div className="flex md:flex-row flex-col justify-center gap-5 shadow-2xl lg:mx-20">
                

                <div className={classNames('basis-2/3 rounded-2xl lg:px-12 px-4 lg:py-12', {'animate__animated animate__fadeInDown': isVisible,})}>
                    <h1 className="text-orange-400 font-extrabold text-2xl sm:text-5xl p-5 text-center">EDUCATION</h1>      
                    <ol className="relative border-s border-orange-400 m-5 justify-center">                  
                        <li className="mb-10 ms-4">
                            <div className="absolute w-3 h-3 bg-orange-400 rounded-full mt-1.5 -start-1.5 border border-white"></div>
                            <time className="mb-1 text-sm font-normal leading-none text-orange-400">June 2021 - Present</time>
                            <h3 className="text-lg font-bold text-tedo-light-yellow">MSc in Electrical and Computer Engineering (Computer Engineering Stream) </h3>
                            <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">An advanced program tailored to provide in-depth knowledge and practical skills in the field of computer engineering. Throughout my academic journey in this program, I have focused on areas such as digital systems design, computer architecture, embedded systems, and advanced algorithms. My thesis, centered on <b className='text-orange-400'>"Maize Diseases Detection and Classification using Deep Learning,"</b> underscores my proficiency in applying cutting-edge technologies to address real-world challenges. With a track record of high academic achievement and a demonstrated capability for rigorous research, I am equipped to tackle complex problems and contribute effectively to the advancement of technology in this field.</p>
                            
                        </li>
                        <li className="mb-10 ms-4">
                            <div className="absolute w-3 h-3 bg-orange-400 rounded-full mt-1.5 -start-1.5 border border-white "></div>
                            <time className="mb-1 text-sm font-normal leading-none text-orange-400">September 2017 - July 2022</time>
                            <h3 className="text-lg font-bold text-tedo-light-yellow">BSc in Electrical &amp; Computer Engineering (Computer Engineering Stream) </h3>
                            <p className="text-base font-normal text-gray-500 dark:text-gray-400">Delves into the fundamentals of electrical and computer engineering with a focus on computer engineering aspects. During my studies, I immersed myself in courses covering digital systems, microprocessors, software engineering, and networking, among others. My thesis, titled <b className='text-orange-400'>"RFID Based Secure Gate Controller,"</b> reflects my dedication to exploring innovative solutions at the intersection of hardware and software. With a strong academic record and successful completion of this project, I've demonstrated my ability to apply theoretical knowledge to practical engineering challenges.</p>
                        </li>
                        <li className="mb-10 ms-4">
                            <div className="absolute w-3 h-3 bg-orange-400 rounded-full mt-1.5 -start-1.5 border border-white "></div>
                            <time className="mb-1 text-sm font-normal leading-none text-orange-400">September 2023 - February 2024</time>
                            <h3 className="text-lg font-bold text-tedo-light-yellow">IBM Data Science Professional Certificate </h3>
                            <p className="text-base font-normal text-gray-500 dark:text-gray-400">Covers a wide range of topics including data analysis, machine learning, data visualization, and more. <b>I am proud to have completed all 10 courses with high grades, earning the certificate as recognition of my proficiency in this area.</b> Throughout the program, I have actively engaged in various projects, applying my learning to real-world scenarios and honing my practical skills. This certification stands as a testament to my commitment to mastering data science techniques and my readiness to tackle data-driven challenges in any professional setting.</p>
                        </li>
                        <li className="mb-10 ms-4">
                            <div className="absolute w-3 h-3 bg-orange-400 rounded-full mt-1.5 -start-1.5 border border-white "></div>
                            <time className="mb-1 text-sm font-normal leading-none text-orange-400">June 2023 - February 2024</time>
                            <h3 className="text-lg font-bold text-tedo-light-yellow">Google Project Management Professional Certificate </h3>
                            <p className="text-base font-normal text-gray-500 dark:text-gray-400">Through a series of online courses, participants learn key concepts, tools, and techniques necessary to effectively plan, execute, and monitor projects. As of now, I have successfully completed 5 out of 6 courses and am currently progressing through the final module. This certificate demonstrates my commitment to mastering project management principles and my readiness to contribute effectively to diverse project teams.</p>
                        </li>

                        <li className="ms-4">
                            <Link to="/education" className="inline-flex items-center px-2 sm:px-4 lg:ms-20 py-2 lg:py-4 rounded-xl text-sm sm:text-md font-medium text-blue-950 bg-orange-400 border border-orange-200 hover:bg-orange-500 hover:text-blue-900 focus:z-10 focus:ring-4 focus:outline-none focus:ring-orange-200 focus:text-orange-400 ">Certificates</Link>
                        </li>
                        
                    </ol>
                </div>

                <div className={classNames('basis-1/3 justify-center bg-inherit p-4 lg:p-20',{'animate__animated animate__fadeInUp': isVisible,})}>
                    <div className="flex flex-col justify-center">
                        <div>
                            <h1 className=" text-2xl sm:text-5xl text-center font-bold pt-4 lg:pt-20 text-orange-400">ADDIS ABABA SCIENCE AND TECHNOLOGY UNIVERSITY </h1>
                            <p className="leading-6 p-4 text-center text-white">University for Industry!</p>
                        </div>
                        <div>
                            <h1 className="text-2xl sm:text-5xl text-center font-bold pt-4 lg:pt-20 text-orange-400">IBM Data Science</h1>
                            <p className="leading-6 p-4 text-center text-white">Coursera</p>
                        </div>
                        <div>
                            <h1 className="text-2xl sm:text-5xl text-center font-bold pt-4 lg:pt-20 text-orange-400">Google Project Management</h1>
                            <p className="leading-6 p-4 text-center text-white">Coursera</p>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}