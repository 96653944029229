import React, { useState } from 'react';
import NeuralNetworks from '../../assets/certificates/Neural-Network-and-Deep-Learning.png';
import WritingEmail from '../../assets/certificates/Write-Professional-Email-in-English.png';



export const OtherCertificates = () => {
  const imageLinks = [
    NeuralNetworks,
    WritingEmail,
  ];

  const [hoveredImage, setHoveredImage] = useState(null);

  const handleImageHover = (image) => {
    setHoveredImage(image);
  };

  const handlePopupClose = () => {
    setHoveredImage(null);
  };

  return (
    <div className="grid gap-4 relative">
      <div className="grid grid-cols-5 gap-4">
        {imageLinks.map((link, index) => (
          <div key={index} className="relative" onClick={() => handleImageHover(link)}>
            <img className="h-auto max-w-full rounded-lg" src={link} alt="Certificate" />
          </div>
        ))}
      </div>
      {hoveredImage && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center">
          <div className="absolute inset-0 bg-black opacity-50" onClick={handlePopupClose}></div>
          <div className="relative z-10 max-w-lg p-4 bg-white rounded-lg">
            <button className="absolute top-2 right-2 text-gray-700" onClick={handlePopupClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
            <img className="w-screen h-auto rounded-lg" src={hoveredImage} alt="Certificates" />
          </div>
        </div>
      )}
    </div>
  );
};
