
import { useTitle } from '../hooks/useTitle';
import { AboutMe } from './AboutMe';
import { Contact, Education, Experience } from './common';
import { MySkills } from './MySkills';
import { DataScience } from './projects/DataScience';



export const HomePage = ({title}) => {
  useTitle(title);

  return (
    <main>       
        <AboutMe />
        <Education />
        <Experience />
        <DataScience />
        <MySkills />
        <Contact />


    </main>
  )
}
