
import classNames from 'classnames';
import 'animate.css/animate.min.css';
import { useEffect, useState, useRef } from 'react';


export const Contact = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    const handleScroll = () => {
        const section = sectionRef.current;
        if (section) {
            const rect = section.getBoundingClientRect();
            setIsVisible(rect.top <= window.innerHeight && rect.bottom >= 0);
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            });
        }, { threshold: 0.5 });

        observer.observe(sectionRef.current);

        // Cleanup function for the observer
        return () => {
            observer.disconnect();
        };
    }, []);

    // Add scroll event listener
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


  return (

        <div ref={sectionRef} id='contact-me'  className="container">
            <h1 className="text-orange-400 font-extrabold text-2xl sm:text-5xl py-12 text-center">Contact Me</h1>
            <div ref={sectionRef} className="container flex justify-center">
                <div className={classNames('flex flex-col justify-center gap-8 px-4 sm:px-20', {'animate__animated animate__fadeInRight': isVisible,})}>
                    <div className="md:max-w-xl w-full bg-inherit border border-orange-400  rounded-2xl p-4 hover:scale-105">
                        <h1 className="text-orange-400 text-2xl font-bold p-4">Phone: <span className='text-tedo-light-yellow'>+251943165195</span></h1>
                        <h1 className="text-orange-400 text-2xl font-bold p-4">Email: <span className='text-tedo-light-yellow'>tedoaba@gmail.com</span></h1>
                        <h1 className="text-orange-400 text-2xl font-bold p-4">LinkedIn: <span className='text-tedo-light-yellow'>linkedin.com/in/tadesse-abateneh/</span></h1>
                        <h1 className="text-orange-400 text-2xl font-bold p-4">GitHub: <span className='text-tedo-light-yellow'>github.com/tedoaba</span></h1>
                    </div>
                </div>
            </div>


        </div>
  )
}
