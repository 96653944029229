
import { useTitle } from '../../hooks/useTitle';
import { DataScienceCard } from './components';
//import { ProjectManagementCard } from './components';

export const ProjectManagement = ({title}) => {
    useTitle(title);

  return (
    <section id="project-management" className= "bg-inherent w-full max-x-2xl mx-auto py-16">
        <h1 className= "text-center text-5xl text-orange-400 font-bold py-7">PPROJECT MANAGEMENT PROJECTS</h1>
        
        <DataScienceCard />

    </section>
  )
}
