import React from 'react'
import { Skills } from './common';

export const MySkills = () => {
  return (
    <main className=' lg:mx-20 lg:px-20'>
        <Skills />
    </main>
  )
}
