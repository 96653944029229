import classNames from 'classnames';
import 'animate.css/animate.min.css';
import React, { useEffect, useRef, useState } from 'react';

export const Skills = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    const handleScroll = () => {
        const section = sectionRef.current;
        if (section) {
            const rect = section.getBoundingClientRect();
            setIsVisible(rect.top <= window.innerHeight && rect.bottom >= 0);
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            });
        }, { threshold: 0.5 });

        observer.observe(sectionRef.current);

        // Cleanup function for the observer
        return () => {
            observer.disconnect();
        };
    }, []);

    // Add scroll event listener
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div ref={sectionRef} className="container py-5 bg-inherit rounded-xl">
            <h1 className="text-orange-400 font-extrabold text-2xl sm:text-5xl p-5 text-center">SKILLS</h1>
            <div className={classNames('flex flex-wrap justify-center gap-8 px-4 sm:px-20', {'animate__animated animate__fadeInLeft': isVisible,})}>

                <button className=" drop-shadow-2xl bg-inherit rounded-2xl p-2 transform translate-x-1 hover:scale-105 border border-orange-400">
                    <h1 className="text-orange-400 text-xs sm:text-sm md:text-xl font-bold p-2">Machine Learning</h1>
                </button>

                <button className=" drop-shadow-2xl bg-inherit rounded-2xl p-2 transform translate-x-1 hover:scale-105 border border-orange-400">
                    <h1 className="text-orange-400 text-xs sm:text-sm md:text-xl font-bold p-2">Deep Learning</h1>
                </button>

                <button className=" drop-shadow-2xl bg-inherit rounded-2xl p-2 transform translate-x-1 hover:scale-105 border border-orange-400">
                    <h1 className="text-orange-400 text-xs sm:text-sm md:text-xl font-bold p-2">Natural Language Processing</h1>
                </button>

                <button className=" drop-shadow-2xl bg-inherit rounded-2xl p-2 transform translate-x-1 hover:scale-105 border border-orange-400">
                    <h1 className="text-orange-400 text-xs sm:text-sm md:text-xl font-bold p-2">Project Management</h1>
                </button>

                <button className=" drop-shadow-2xl bg-inherit rounded-2xl p-2 transform translate-x-1 hover:scale-105 border border-orange-400">
                    <h1 className="text-orange-400 text-xs sm:text-sm md:text-xl font-bold p-2">Web Development</h1>
                </button>

                <button className=" drop-shadow-2xl bg-inherit rounded-2xl p-2 transform translate-x-1 hover:scale-105 border border-orange-400">
                    <h1 className="text-orange-400 text-xs sm:text-sm md:text-xl font-bold p-2">Research</h1>
                </button>

                <button className=" drop-shadow-2xl bg-inherit rounded-2xl p-2 transform translate-x-1 hover:scale-105 border border-orange-400">
                    <h1 className="text-orange-400 text-xs sm:text-sm md:text-xl font-bold p-2">Tensorflow</h1>
                </button>

                <button className=" drop-shadow-2xl bg-inherit rounded-2xl p-2 transform translate-x-1 hover:scale-105 border border-orange-400">
                    <h1 className="text-orange-400 text-xs sm:text-sm md:text-xl font-bold p-2">Pytorch</h1>
                </button>

                <button className=" drop-shadow-2xl bg-inherit rounded-2xl p-2 transform translate-x-1 hover:scale-105 border border-orange-400">
                    <h1 className="text-orange-400 text-xs sm:text-sm md:text-xl font-bold p-2">Keras</h1>
                </button>

                <button className=" drop-shadow-2xl bg-inherit rounded-2xl p-2 transform translate-x-1 hover:scale-105 border border-orange-400">
                    <h1 className="text-orange-400 text-xs sm:text-sm md:text-xl font-bold p-2">matplotlib</h1>
                </button>

                <button className=" drop-shadow-2xl bg-inherit rounded-2xl p-2 transform translate-x-1 hover:scale-105 border border-orange-400">
                    <h1 className="text-orange-400 text-xs sm:text-sm md:text-xl font-bold p-2">pandas</h1>
                </button>

                <button className=" drop-shadow-2xl bg-inherit rounded-2xl p-2 transform translate-x-1 hover:scale-105 border border-orange-400">
                    <h1 className="text-orange-400 text-xs sm:text-sm md:text-xl font-bold p-2">numpy</h1>
                </button>

                <button className=" drop-shadow-2xl bg-inherit rounded-2xl p-2 transform translate-x-1 hover:scale-105 border border-orange-400">
                    <h1 className="text-orange-400 text-xs sm:text-sm md:text-xl font-bold p-2">scikit-learn</h1>
                </button>

                <button className=" drop-shadow-2xl bg-inherit rounded-2xl p-2 transform translate-x-1 hover:scale-105 border border-orange-400">
                    <h1 className="text-orange-400 text-xs sm:text-sm md:text-xl font-bold p-2">seaborn</h1>
                </button>

                <button className=" drop-shadow-2xl bg-inherit rounded-2xl p-2 transform translate-x-1 hover:scale-105 border border-orange-400">
                    <h1 className="text-orange-400 text-xs sm:text-sm md:text-xl font-bold p-2">streamlit</h1>
                </button>

                <button className=" drop-shadow-2xl bg-inherit rounded-2xl p-2 transform translate-x-1 hover:scale-105 border border-orange-400">
                    <h1 className="text-orange-400 text-xs sm:text-sm md:text-xl font-bold p-2">Dash</h1>
                </button>

                <button className=" drop-shadow-2xl bg-inherit rounded-2xl p-2 transform translate-x-1 hover:scale-105 border border-orange-400">
                    <h1 className="text-orange-400 text-xs sm:text-sm md:text-xl font-bold p-2">plotly</h1>
                </button>

                <button className=" drop-shadow-2xl bg-inherit rounded-2xl p-2 transform translate-x-1 hover:scale-105 border border-orange-400">
                    <h1 className="text-orange-400 text-xs sm:text-sm md:text-xl font-bold p-2">ReactJs</h1>
                </button>

                <button className=" drop-shadow-2xl bg-inherit rounded-2xl p-2 transform translate-x-1 hover:scale-105 border border-orange-400">
                    <h1 className="text-orange-400 text-xs sm:text-sm md:text-xl font-bold p-2">Tailwind CSS</h1>
                </button>

                <button className=" drop-shadow-2xl bg-inherit rounded-2xl p-2 transform translate-x-1 hover:scale-105 border border-orange-400">
                    <h1 className="text-orange-400 text-xs sm:text-sm md:text-xl font-bold p-2">Bootstrap 5</h1>
                </button>

                <button className=" drop-shadow-2xl bg-inherit rounded-2xl p-2 transform translate-x-1 hover:scale-105 border border-orange-400">
                    <h1 className="text-orange-400 text-xs sm:text-sm md:text-xl font-bold p-2">JavaScript</h1>
                </button>

                <button className=" drop-shadow-2xl bg-inherit rounded-2xl p-2 transform translate-x-1 hover:scale-105 border border-orange-400">
                    <h1 className="text-orange-400 text-xs sm:text-sm md:text-xl font-bold p-2">Folium</h1>
                </button>

                <button className=" drop-shadow-2xl bg-inherit rounded-2xl p-2 transform translate-x-1 hover:scale-105 border border-orange-400">
                    <h1 className="text-orange-400 text-xs sm:text-sm md:text-xl font-bold p-2">Agile</h1>
                </button>

                <button className=" drop-shadow-2xl bg-inherit rounded-2xl p-2 transform translate-x-1 hover:scale-105 border border-orange-400">
                    <h1 className="text-orange-400 text-xs sm:text-sm md:text-xl font-bold p-2">Kaggle</h1>
                </button>

                <button className=" drop-shadow-2xl bg-inherit rounded-2xl p-2 transform translate-x-1 hover:scale-105 border border-orange-400">
                    <h1 className="text-orange-400 text-xs sm:text-sm md:text-xl font-bold p-2">Colab</h1>
                </button>

                <button className=" drop-shadow-2xl bg-inherit rounded-2xl p-2 transform translate-x-1 hover:scale-105 border border-orange-400">
                    <h1 className="text-orange-400 text-xs sm:text-sm md:text-xl font-bold p-2">GitHub</h1>
                </button>

                <button className=" drop-shadow-2xl bg-inherit rounded-2xl p-2 transform translate-x-1 hover:scale-105 border border-orange-400">
                    <h1 className="text-orange-400 text-xs sm:text-sm md:text-xl font-bold p-2">GitLab</h1>
                </button>

            </div>
        </div>
    );
};
