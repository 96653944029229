import { useTitle } from "../hooks/useTitle";
import { Experience } from "./common";


export const MyExperience = ({title}) => {
    useTitle(title);
  return (
    <main>
        <Experience />
    </main>
  )
}
