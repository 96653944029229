//import Logo from '../assets/tedoaba-log.png'
import { Link } from 'react-router-dom';
import { useEffect, useState, useRef } from "react";
//import { CTAs } from "./common/CTAs"
import classNames from 'classnames';
import 'animate.css/animate.min.css';


export const AboutMe = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    const handleScroll = () => {
        const section = sectionRef.current;
        if (section) {
            const rect = section.getBoundingClientRect();
            setIsVisible(rect.top <= window.innerHeight && rect.bottom >= 0);
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            });
        }, { threshold: 0.5 });

        observer.observe(sectionRef.current);

        // Cleanup function for the observer
        return () => {
            observer.disconnect();
        };
    }, []);

    // Add scroll event listener
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

  return (
    <section ref={sectionRef}  className="pt-8 pb-4 bg-inherit">

        <div className="container pb-10">
            <div className={classNames('flex flex-col justify-center', {'animate__animated animate__fadeInLeft': isVisible,})}>
                <div className={classNames({'animate__animated animate__zoomInDown': isVisible,})}>
                    <h1 className="text-center font-playfairDisplay mb-4 text-4xl font-extrabold tracking-tight leading-none text-orange-400 md:text-5xl lg:text-6xl py-3 lg:pt-12">
                    TADESSE ABATENEH
                    </h1>
                </div>
                <div className="basis-1/2 py-4 justify-center">
                    <h1 className="pb-4 px-4 md:px-20 lg:px-40 xl:px-60 text-2xl text-center font-merriweather font-semibold text-orange-400">Data Science | Project Management | ReactJS |</h1>
                    <p className="text-gray-400 px-4 md:px-20 lg:px-40 text-xl text-center">Versatile professional combining Data Science expertise with Project Management and Web-Development skills. Proven track record in extracting insights from data and crafting seamless digital experiences. Skilled in statistical analysis, machine learning, and front-end development. Dedicated to driving innovation at the intersection of technology and business.</p>
                </div>
            </div>
        </div>
        <div className={classNames('justify-center flex mt-4 sm:justify-center sm:mt-0',{'animate__animated animate__zoomInDown': isVisible,})}>
                    {/* Social media links */}
                    <Link to="https://www.linkedin.com/in/
tadesse-abateneh" className=" ms-5" target='_blank'>
                        <div class="rounded-full p-2 bg-blue-900 hover:bg-white ">
                            <svg class="w-6 h-6 text-white hover:text-blue-900" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 15 15">
                                <path fillRule="evenodd" d="M7.979 5v1.586a3.5 3.5 0 0 1 3.082-1.574C14.3 5.012 15 7.03 15 9.655V15h-3v-4.738c0-1.13-.229-2.584-1.995-2.584-1.713 0-2.005 1.23-2.005 2.5V15H5.009V5h2.97ZM3 2.487a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" clipRule="evenodd"/>
                                <path d="M3 5.012H0V15h3V5.012Z"/>
                            </svg>
                        </div>
                        <span className="sr-only">LinkedIn</span>
                    </Link>

                    <Link to="https://www.twitter.com/tedoaba" target='_blank'  className=" ms-5">
                        <div class="rounded-full p-2 bg-black hover:bg-white ">
                            <svg class="w-6 h-6 text-white hover:text-black" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M13.8 10.5 20.7 2h-3l-5.3 6.5L7.7 2H1l7.8 11-7.3 9h3l5.7-7 5.1 7H22l-8.2-11.5Zm-2.4 3-1.4-2-5.6-7.9h2.3l4.5 6.3 1.4 2 6 8.5h-2.3l-4.9-7Z"/>
                            </svg>
                        </div>
                        <span className="sr-only">Twitter page</span>
                    </Link>

                    <Link to="https://www.github.com/tedoaba" target='_blank'  className=" ms-5">
                        <div className="rounded-full p-2 bg-white hover:bg-black">
                            <svg class="w-6 h-6 text-black hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path fillRule="evenodd" d="M10 .333A9.911 9.911 0 0 0 6.866 19.65c.5.092.678-.215.678-.477 0-.237-.01-1.017-.014-1.845-2.757.6-3.338-1.169-3.338-1.169a2.627 2.627 0 0 0-1.1-1.451c-.9-.615.07-.6.07-.6a2.084 2.084 0 0 1 1.518 1.021 2.11 2.11 0 0 0 2.884.823c.044-.503.268-.973.63-1.325-2.2-.25-4.516-1.1-4.516-4.9A3.832 3.832 0 0 1 4.7 7.068a3.56 3.56 0 0 1 .095-2.623s.832-.266 2.726 1.016a9.409 9.409 0 0 1 4.962 0c1.89-1.282 2.717-1.016 2.717-1.016.366.83.402 1.768.1 2.623a3.827 3.827 0 0 1 1.02 2.659c0 3.807-2.319 4.644-4.525 4.889a2.366 2.366 0 0 1 .673 1.834c0 1.326-.012 2.394-.012 2.72 0 .263.18.572.681.475A9.911 9.911 0 0 0 10 .333Z" clip-rule="evenodd"/>
                            </svg>
                        </div>
                        <span className="sr-only">GitHub account</span>
                    </Link>
                    <Link to="https://www.gitlab.com/tedoabag" target='_blank'  className=" ms-5">
                        <div className="rounded-full p-2 bg-white hover:bg-orange-400">
                            <svg class="w-6 h-6 text-orange-400 hover:text-white bi bi-gitlab" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path d="m15.734 6.1-.022-.058L13.534.358a.57.57 0 0 0-.563-.356.6.6 0 0 0-.328.122.6.6 0 0 0-.193.294l-1.47 4.499H5.025l-1.47-4.5A.572.572 0 0 0 2.47.358L.289 6.04l-.022.057A4.044 4.044 0 0 0 1.61 10.77l.007.006.02.014 3.318 2.485 1.64 1.242 1 .755a.67.67 0 0 0 .814 0l1-.755 1.64-1.242 3.338-2.5.009-.007a4.05 4.05 0 0 0 1.34-4.668Z"/>
                            </svg>
                        </div>
                    </Link>

                    
                </div>

    </section>
  )
}
