import { useTitle } from "../hooks/useTitle";
import { Certificate2, OtherCertificates, PRCertificates } from "./about";
import { Education } from "./common";


export const MyEducation = ({title}) => {
    useTitle(title);
  return (
    <main>
        <Education />
        <section className="py-16 lg:px-20">
          <h1 className="text-center font-extrabold text-5xl text-blue-950 py-5">Data Science Certificates</h1>
          <Certificate2 />
        </section>
        <section className="py-16 lg:px-20">
          <h1 className="text-center font-extrabold text-5xl text-blue-950 py-5">Project Management Certificates</h1>
          <PRCertificates />
        </section>
        <section className="py-16 lg:px-20">
          <h1 className="text-center font-extrabold text-5xl text-blue-950 py-5">Other Certificates</h1>
          <OtherCertificates />
        </section>
        
    </main>
  )
}
