

import { useTitle } from '../../hooks/useTitle';
import { DataScienceCard } from './components';
//import { WebDevCard } from './components';

export const WebDev = ({title}) => {
    useTitle(title);
  return (
    <section id="web-development" className= "bg-inherent w-full max-x-2xl mx-auto py-16">
        <h1 className= "text-center text-5xl text-orange-400 font-bold py-7">WEB DEVELOPMENT PROJECTS</h1>
        
        <DataScienceCard />

    </section>
  )
}
